@import 'App.scss';

.title {
  padding: 0px;
  margin-top: 1rem;
  font-weight: bold;
}

.tableContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 300px);
}
.fixedRow {
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
}
.tableRow {
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  p {
    color: #666666;
  }
}

.selectedRow {
  background-color: $white !important;
  color: #2a6e91 !important;
  transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  box-shadow: 0px 4px 16px 0px rgba(148, 148, 148, 0.16);
  p {
    color: #00040d;
  }
}

.tableBody {
  overflow-y: scroll;
}

.tableCell {
  border-radius: 10px !important;
  border-bottom: none !important;
  padding: 8px !important;
  margin: 2px;
  p {
    margin: 0 !important;
  }
}

.nameCell {
  font-size: 16px;
}

.valueCell {
  color: #707a8b !important;
  background-color: rgba(245, 247, 250, 255) !important;
  text-align: center !important;
  border-radius: 100px;
  min-width: 30px;
  padding: 3px 8px;
  float: right;
  font-size: 16px;
}

.circularProgress {
  position: relative;
  background-color: transparent !important;
  text-align: center;
  top: 50%;
  left: 50%;
}
