.dividerContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.textContent {
  font-size: 20px;
  color: #000000;
  flex-grow: auto;
  margin-right: 0px;
}

.divider {
  flex-grow: 1;
}
