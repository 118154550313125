@import 'App.scss';

.loginWrapperContainer {
  height: 100%;
  grid-template-columns: 1fr 1fr;
  .demoTxtContainer {
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 7px;
  }
}

.fullWidth {
  width: 100%;
}

.logoContainer {
  display: flex;
  justify-content: center;
  img {
    height: 100px;
    width: auto;
  }
}

.fullWidth {
  width: 100%;
}

.datePickerInput {
  input {
    width: 8rem !important;
  }
}

.bgGrey {
  background-color: $body;
  grid-template-rows: 1fr auto;
}

.fieldContainer {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.registerPopContainer {
  position: absolute;
  top: 0px;
  z-index: 2;
  background: $white;
  .registerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    .link {
      text-decoration: underline;
    }
  }
}

.powerContainer {
  display: flex !important;
  justify-content: center;
}

.marketingContainer {
  position: relative;
  grid-gap: 1rem;
  background: linear-gradient(154.2deg, #000000 -1.12%, rgba(8, 8, 8, 0.79) 49.86%, #000000 101.94%);
  box-shadow: 8px 0px 32px -8px rgba(0, 0, 0, 0.16);
  border-radius: 0px 8px 8px 0px;
  grid-template-rows: auto 1rem auto;

  .marketingContent {
    font-size: 30px;
    text-align: center;
    color: white;
  }

  .marketingIconContainer {
    display: grid;
    justify-content: center;
    align-items: end;
  }

  .betaContainer {
    background-color: $secondary;
    position: absolute;
    height: 500px;
    width: 600px;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .infoContainer {
    padding: 24px 32px;
  }

  .surveyLink {
    text-decoration: none;
    color: $primary;
  }
  .surveyLink:hover {
    text-decoration: underline;
  }

  .betaVersion {
    position: absolute;
    left: 50%;
    bottom: 16px;
    color: $secondary;
    transform: translateX(-50%);
  }
}

.loginContainer {
  height: auto;
  width: 30rem !important;
  margin: auto;
  grid-gap: 1rem;
  .brandDetail {
    grid-gap: 1rem;
  }
  .formContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contentLinkContainer {
    grid-template-columns: auto auto;
    align-items: center;
    padding-bottom: 1rem;
  }
}

.buttonContainer {
  flex-grow: 1;
  height: 50px;
  margin-top: 16px;
  div {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  font-size: 35px;
  margin: 4px;
}

.otpContainer {
  justify-content: center;
  padding: 1rem;
}

.resendOtpContainer {
  grid-template-columns: 1fr 1fr;
}
