.sectionFields {
  margin-bottom: 20px;
}

.sectionFieldLastRow {
  margin-bottom: 20px;
  margin-right: 100px;
}
.sectionContentRowLabel {
  font-size: 15px !important;
  line-height: 24px !important;
  color: #848484;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.labelTruncate {
  max-width: 300px;
}

.sectionContentRowValue {
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}

.preLine {
  white-space: pre-line;
}
