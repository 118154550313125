@import 'App.scss';

.sectionContentContainer {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  column-gap: 5%;
}

.sectionTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  color: $primary;
}

.quickPayFeeHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.notQuickPayFeeMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
