@import 'App.scss';

.checklistContainer {
    padding: 5px;
    font-size: 16px;
    .textContainer {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .headerStyle {
        font-weight: 500 !important;
    }
    .valid {
        color: $success;
    }
    .notValid {
        color: $error;
    }
    .ml2 {
        margin-left: 10px;
    }
}

.tooltipContainer {
    width: 100%;
}