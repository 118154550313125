@import 'App.scss';

.headerContainer {
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  padding-bottom: 8px;

  .headerAction {
    display: flex;
    gap: 8px;
    height: 2rem;
    width: auto;
    margin-top: -40px;
  }
}
