@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

.factorCloudScrollbar {
  overflow-y: scroll;
}
.factorCloudScrollbar::-webkit-scrollbar {
  width: 5px;
}
.factorCloudScrollbar::-webkit-scrollbar-corner {
  display: none;
}
.factorCloudScrollbar::-webkit-scrollbar-thumb {
  background: rgb(181, 178, 178);
  border-radius: 10px;
}
.factorCloudScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

#quill-email-template {
  .ql-toolbar {
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    border-radius: 0 0 4px 4px;
    height: 90%;
  }

  .ql-editor {
    height: 89%;
  }

  .css-1laqsz7-MuiInputAdornment-root {
    flex-direction: row-reverse;
    margin-right: 10px;
  }
}

.tooltipsOverflowedTableDataColumns {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#fc-icon-accordion {
  @include transform(rotate(0deg));
  transition: transform 0.2s;
}
.Mui-expanded {
  #fc-icon-accordion {
    @include transform(rotate(180deg));
  }
}
