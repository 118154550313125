@import 'App.scss';

.sectionLayoutContainer {
  margin-bottom: 8px;
  //border: none !important;
  border-radius: 8px !important;
  //box-shadow: 0px 4px 56px -16px rgba(red, 70%) !important;
}

.sectionLayoutHeader {
  display: flex;
  padding: 8px 16px;
  border-bottom: 1px solid $border;

  .sectionLayoutTitle {
    line-height: 36px;
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
  }
  .sectionLayoutButton {
    flex-basis: 100px;
    text-align: right;
  }
}

.sectionLayoutContent {
  padding-right: 16px;
  padding-left: 16px;
}
