.scrollSpyContainer {
  display: flex;
  gap: 8px;

  height: calc(100vh - 52px - 48px - 32px);
  overflow-y: hidden;

  .scrollSpySidebar {
    min-width: 250px;
  }

  .scrollSpyContent {
    flex-grow: 1;
    height: 100%;
  }
}
