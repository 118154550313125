@import 'App.scss';

.breadcrumbs {
  display: flex !important;
  align-items: center;
  margin-bottom: 8px;
  > div {
    margin-right: 10px;
    font-size: 20px;
  }
  .breadcrumbIcon {
    margin-right: 10px;
  }
}
