@import 'App.scss';

.menuList {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.textOverflow {
  text-overflow: ellipsis;
  width: 10rem;
  overflow: hidden;
}

.closedIconContainer > img {
  object-fit: contain;
}

.logoContainer {
  display: flex;
  justify-content: center;
  img {
    height: 50px;
    width: auto;
  }
}

.stickyMenuList {
  position: sticky !important;
  top: 100%;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.leftIconButton {
  position: fixed !important;
  z-index: 1201;
  left: 240px;
  padding: 3px !important;
  background: $white !important;
  top: 60px;
  box-shadow: 0px 1px 3px 0px;
}

.rightIconButton {
  left: 64px;
}

.captionContainer {
  grid-template-columns: auto auto;
  align-items: center;
  margin-top: 2rem;
}

.mt2 {
  margin-top: 2rem;
}

.openInTab {
  text-decoration: none;
  color: inherit;
}

.environment {
  position: absolute;
  color: white;
  right: 16px;
  top: 44px;
  font-family: monospace, sans-serif;
  font-size: small;
}
