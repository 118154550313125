@import 'App.scss';

.actionContainer {
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  padding: 12px;
  justify-content: end;
  border-top: 1px solid #e0e0e0;
  height: 70px !important;
}

.sectionTitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.twoRowFormat {
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
}
