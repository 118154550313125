@import 'App.scss';

.fc-menu-bar {
  .MuiDrawer-root {
    .MuiPaper-root {
      background-color: $primary;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.fc-menu-bar-item {
  color: $secondary;

  .MuiListItemIcon-root > .active > svg > path {
    fill: $secondary;
  }
}

.fc-menu-bar-item.active {
  background-color: rgba($secondary, 18%);
  border-radius: 8px;
}

.fc-button-container {
  background-color: $backgroundLight;
  border-radius: 8px;
  padding: 8px;
}

.fc-selected-button {
  background-color: $secondary;
  color: $primary;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(#949494, 16%);
  font-weight: bold;
}

.fc-tabs {
  padding-bottom: 8px;
  .MuiTabs-flexContainer {
    @extend .fc-button-container;
    width: fit-content;
  }

  .MuiButtonBase-root.MuiTab-root {
    color: $textLight;
    padding: 8px 16px;
    line-height: 12px;
    min-height: 29px;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    @extend .fc-selected-button;
  }
}

.fc-table {
  .MuiDataGrid-root {
    border: none;
  }
}

.fc-hide-header-checkbox {
  .MuiDataGrid-columnHeaderCheckbox {
    visibility: hidden !important;
  }
}

.fc-scrollspy {
  .MuiList-root {
    @extend .fc-button-container;

    .MuiListItemButton-root {
      color: $textLight;
      border-radius: 8px;
    }

    svg {
      path {
        fill: $textLight;
      }
    }

    .MuiListItemButton-root.Mui-selected {
      @extend .fc-selected-button;
      .MuiListItemText-primary {
        font-weight: bold;
      }
    }
  }

  .fc-scrollspy-section {
    .MuiCardContent-root {
      padding: 0;
    }
  }
}

.fc-invoice-company-type {
  .MuiTabs-flexContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
  }
}

.fc-invoice-company-list {
  padding: 8px;
  .MuiTableRow-root.selected {
    background-color: rgba($primary, 8%);
    th {
      border-radius: 8px 0px 0px 8px;
    }
    td {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.fc-relationship-status-list {
  padding: 8px;
  .MuiTableRow-root.selected {
    background-color: rgba($primary, 8%);
    th {
      border-radius: 8px 0px 0px 8px;
    }
    td {
      border-radius: 0px 8px 8px 0px;
    }
  }
}
