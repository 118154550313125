@import 'App.scss';

.errorBoundary {
  padding: 16px;
  margin: 16px;
  border: 1px solid #e9e9e9;
  box-shadow: 1px 5px 16px rgb(0 0 0 / 3%);
  border-radius: 14px;
  h1 {
    font-family: 'Inter-Medium', sans-serif;
  }
  p {
    font-family: 'Inter-Regular', sans-serif;
  }
}
