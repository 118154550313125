.creatableContainer {
  margin-top: 1rem;
  width: 100%;
  .inputWithAdornment {
    position: relative;
    .endAdornment {
      top: 30%;
      right: 4%;
      position: absolute;
      margin-left: -8px; // Espacio entre el campo y el adorno
      display: flex;
      align-items: center;
      z-index: 1;
    }
  }
  .errorMessage {
    color: rgb(255, 57, 57);
    font-size: 12px;
    margin-left: 15px;
  }
}
