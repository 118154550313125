@import 'react-toastify/dist/ReactToastify.css';

/*colors variables*/

$primary: #00040d;
$secondary: #fff;
$backgroundLight: #f8f8f8;
$textLight: #666666;
$border: #e0e0e0;

$error: #f9635e;
$success: #5abfae;
$warning: #f5b529;
$waiting: #f2b90c;
$white: #ffffff;
$subTitle1: #636363;
$body: #f8f8f8;
$paper: #f8f8f8;
$grey: rgb(102, 102, 102);
$scrollBar: rgb(181 178 178);
$borderFields: #9e9e9e;

/*box-shadow variables*/

$bladeboxShadow: -8px -2px 20px -10px rgba(0, 0, 0, 0.25);

/*background linear gradient*/
$bg-linear-gradient: linear-gradient(270deg, $primary 0%, #6277ae 100%);

/* font family config */

@font-face {
  font-family: 'Inter-Medium'; /*Can be any text*/
  src: local('Inter-Medium'), url('./theme/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Regular'; /*Can be any text*/
  src: local('Inter-Regular'), url('./theme/fonts/Inter-Regular.ttf') format('truetype');
}

main {
  min-height: 100vh !important;
}

body {
  margin: 0 !important;
}

/*MUI style overrides*/

.MuiListItemIcon-root > .active > svg > g path {
  stroke: $primary;
}

.MuiListItemIcon-root > .active > svg > path {
  fill: $primary;
}

.MuiPopover-paper {
  box-shadow: 0px 4px 16px 0px rgba(33, 33, 33, 0.08) !important;
  border: 1px solid #e0e0e0 !important;
}

.MuiDataGrid-panel {
  left: 62% !important;
  top: -4rem !important;
  width: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
