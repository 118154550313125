.dropzone {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0px;
}

.dropzoneCaption {
  color: #666666;
  font-size: 12px;
  text-align: center;
}

.dropZoneContainer:hover {
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(229, 246, 253, 255);
}

.dropzoneLayoutContainer {
  padding: 30px 8px !important;
  position: relative;
  height: 206px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dropzoneBtnContainer {
  text-align: center;
  margin-top: 1rem;
}

.dropDownFormField {
  width: 100%;
}

.textFormField {
  width: 100%;
}

.circularProgress {
  position: relative;
  background-color: transparent !important;
  text-align: center;
  top: 50%;
  left: 10%;
}

.CheckCircle {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
