@import 'App.scss';

$heightHeaderContainer: 80px;
$heightFooterContainer: 70px;
$heightBodyContainer: calc(100vh - (#{$heightHeaderContainer} + #{$heightFooterContainer}));

.wrapper {
  height: 100vh;
  display: grid;
  align-items: start;
  grid-template-rows: auto 1fr auto;
}

.containerShadow > div {
  box-shadow: 0px 16px 24px 4px rgba(18, 28, 45, 0.2);
}

.headerContainer {
  border-bottom: 1px solid #e0e0e0;
  padding: 4px 16px;
  .title {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.headerContainer > .headerContent {
  display: grid;
  grid-template-columns: 1fr auto;
  height: auto;
}

.headerContainer > .headerContent > button {
  margin: auto;
}

.bodyContainer {
  overflow-y: scroll;
  overflow-x: scroll;
  padding-left: 16px;
  padding-right: 16px;
  align-items: start !important;
  height: $heightBodyContainer !important;
}

.bodyContainerAdditionalInfo {
  overflow-y: visible;
  overflow-x: scroll;
  padding: 0px !important;
  align-items: start !important;
  height: 100vh; // because the additional info doesn't have action buttons
}

/* width */
.bodyContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.bodyContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.bodyContainer::-webkit-scrollbar-thumb {
  background: rgb(181 178 178);
  border-radius: 10px;
}

.footerContainer {
  border-top: 1px solid #e0e0e0;
  height: $heightFooterContainer !important;
}

.footerContainer > .footerContent {
  display: grid;
  grid-template-columns: 1fr auto;
  height: auto;
  padding: 0px 16px;
}

.footerBtn {
  grid-template-columns: auto auto;
  grid-gap: 1rem;
}

.footerContainer > .footerContent > .footerBtn {
  margin: auto;
}

.footerContainer > .footerContent > .footerBtn > button:first-of-type {
  margin: 0 10px;
}

.hasBladeInfoContainer {
  width: 52vw;
  height: 100vh;
  background-color: #fafafa;
}

.smallAdditionalBlade {
  width: 480px !important;
}

.bodyContainerAdditionalInfoSmall {
  overflow-y: visible;
  overflow-x: scroll;
  padding: 0px 20px;
  height: 100vh; // because the additional info doesn't have action buttons
}

.headerContainerInfo {
  border-bottom: 1px solid #e0e0e0;
  height: 49px;
  padding: 4px 16px;
  .title {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.mainTitleContainer {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid #e0e0e0;
}
