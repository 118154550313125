@import 'App.scss';

.selectionBg {
  background-color: $secondary;
}

.recordContains {
  font-weight: 100;
  font-size: 14px;
  color: #434a54;
}

.recordValue {
  font-size: 16px;
  font-weight: bold;
  color: #434a54;
}

.clearBtn {
  margin-top: 10px !important;
}

.chipLabelContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 6px;
  justify-content: center;
  align-items: center;
  color: #434a54;

  .chipLabel {
    font-size: 13px;
    font-weight: bold;
  }

  .chipCondition {
    font-size: 12px;
    font-style: italic;
  }

  .chipValue {
    font-size: 14px;
    // font-weight: bold;
  }
}

.emptyResultContainer {
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
  }
}

.tableHeaderBarContainer {
  grid-template-columns: 1fr auto;
  .buttonHeader {
    margin-right: 1rem;
  }
}

.tableHeaderBarContainer.tableHeaderBarAction {
  grid-template-columns: repeat(4, 1fr);
}

.selectAllRecordsInAllPagesContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #2E2E2E;
  gap: 16px;
  color: $secondary;
  padding: 8px;

  .selectAllRecordsInAllPages {
    cursor: pointer;
    text-decoration: underline;
  }
}

.paginationContainer {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2px;
  align-items: center;
  gap: 20px;
  .paginationSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 11px;
    .selectorLabel {
      font-size: 14px;
      letter-spacing: 0.2px;
    }
    .paginationDropdown {
      margin-bottom: 1rem;
    }
  }
  .paginationNumberInformation {
    margin-top: 1px;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }
  .paginationIconsContainer {
  }
}

.buttonHeader {
  display: inline-block !important;
  max-width: auto !important;
  padding: 10px 20px;
  margin-right: 8px !important;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttonHeader.small {
  font-size: 14px;
  padding: 8px 16px;
}

.buttonHeader.large {
  font-size: 20px;
  padding: 12px 24px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.tableHeaderBarSelectionModeContainer {
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  grid-auto-rows: auto;
  grid-template-rows: auto;
  .filterBarContainer {
    margin-left: auto;
    .filterBarButton {
      border-radius: 50% !important;
      min-width: 0px !important;
      width: 35px;
      height: 35px;
      span {
        margin: inherit !important;
      }
    }
    .rowSelectionContainer {
      grid-template-columns: 1fr 3rem 3rem;
      align-items: center;
      grid-gap: 1rem;
    }
  }
}

.tableHeaderBarSelectionModeContainer.tableHeaderBarAction {
  grid-template-columns: 1fr auto;
  background: #ebebec;
}

.notTitleHeaderBarContainer {
  grid-template-columns: auto auto;
  align-items: center;
  padding: 12px;
  grid-auto-rows: auto;
  grid-template-rows: auto;
  .filterBarContainer {
    margin-left: auto;
    .filterBarButton {
      border-radius: 50% !important;
      min-width: 0px !important;
      width: 10px;
      height: 10px;
      span {
        margin: inherit !important;
      }
    }
    .rowSelectionContainer {
      padding-top: 2rem;
      grid-template-columns: 1fr 3rem 3rem;
      align-items: center;
      grid-gap: 1rem;
    }
  }
}

.MuiDataGrid {
  .footerContainer {
    display: none;
  }
}

.filterContainer {
  border-top: 1px solid #8080803b;
  display: grid;
  grid-template-columns: 3rem 3rem auto;
  align-items: center;
  .moreContainer {
    justify-content: center;
    border-right: 1px solid #8080803b;
  }
  .filterIconContainer {
    justify-content: center;
  }
  .noFieldset {
    margin: 0;
    div {
      fieldset {
        display: none;
      }
    }
  }
}

.hideFilterIcon {
  grid-template-columns: 3rem auto !important;
}

.filterController {
  min-width: 15rem;
  .headerBar {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #e0e0e0;
    background: #f8f8f8;
    align-items: center;
  }
  .controller {
    padding: 5px 10px;
  }
  .submitButton {
    padding: 10px;
    .button {
      width: 100%;
      text-align: end;
    }
  }
}

.filterListContainer {
  min-width: 15rem;
  padding: 10px;
}

.detailContent {
  display: flex !important;
  justify-content: flex-end;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  background: #ffffff;
  position: relative; /* Agregamos una posición relativa al contenedor principal */
}
