.contentContainer {
  padding-top: 30px;
  padding-left: 130px;
  padding-right: 200px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.label {
  font-size: 20px;
}

.value {
  font-size: 20px;
}

.summaryContainer {
  background-image: linear-gradient(to bottom, #0074a4, #00638d);
  width: 100%;
  height: 100px;
  color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: -2px -2px 20px -10px rgba(0, 0, 0, 0.25);
}
